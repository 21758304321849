.wrapper {
    background-color: #272525;
    height: 250px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.odd {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    font-weight: 700;

    &.crash {
        color: #F40808;
    }

    &.win {
        color: #01D501;
    }
}