.results {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
    overflow-x: hidden;
}

.results__item {
    min-width: 50px;
    width: 50px;
    height: 30px;
    border-radius: 5px;
    background-color: #3A3737;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.results__item_green {
    background-color: #01D501;
}